var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"10"}},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.modalTitle))])]),_c('v-col',{staticClass:"py-0 pr-2",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"to-right",attrs:{"icon":""},on:{"click":_vm.closeModal}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.closeIcon))])],1)]}}])},[_c('span',[_vm._v("Cerrar")])])],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveEdit()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',{staticClass:"pb-0"},[_c('v-col',{staticClass:"py-0 pr-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.conveniosItems,"label":"Convenio","item-text":"value","item-value":"id","outlined":"","dense":"","rules":_vm.rules.required,"return-object":""},on:{"change":function($event){return _vm.setPlanesByConvenios()}},model:{value:(_vm.convenioSelected),callback:function ($$v) {_vm.convenioSelected=$$v},expression:"convenioSelected"}})],1),_c('v-col',{staticClass:"py-0 pl-1 pr-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"outlined":"","clearable":"","dense":"","item-text":"value","item-value":"id","multiple":_vm.mascaraCarenciaAutoId == 0,"return-object":"","loading":_vm.loadingPlanesByConvenio,"label":"Planes","items":_vm.planesFiltrados},scopedSlots:_vm._u([(_vm.mascaraCarenciaAutoId == 0)?{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.planesToggle()}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.planesSelected.length > 0 ? 'primary' : ''}},[_vm._v(" "+_vm._s(_vm.multiselectIconPlanes)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Todos ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}:null,(_vm.mascaraCarenciaAutoId == 0)?{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.value))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.planesSelected.length - 1)+" otros) ")]):_vm._e()]}}:null],null,true),model:{value:(_vm.planesSelected),callback:function ($$v) {_vm.planesSelected=$$v},expression:"planesSelected"}})],1),_c('v-col',{staticClass:"py-0 pl-1 pr-0",attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha desde","append-icon":_vm.calendarIcon,"autocomplete":"not","clearable":"","hint":"Formato DD/MM/AAAA","rules":_vm.rules.required.concat(
                    _vm.rules.validDate,
                    _vm.fechaSelectedHasta
                      ? [
                          new Date(_vm.parseDateToIso(_vm.fechaSelectedDesde)) <=
                            new Date(_vm.parseDateToIso(_vm.fechaSelectedHasta)) ||
                            'Rango no válido'
                        ]
                      : []
                  ),"outlined":"","dense":""},on:{"blur":function($event){_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaSelectedDesde)}},model:{value:(_vm.fechaSelectedDesde),callback:function ($$v) {_vm.fechaSelectedDesde=$$v},expression:"fechaSelectedDesde"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaDesde),callback:function ($$v) {_vm.menuFechaDesde=$$v},expression:"menuFechaDesde"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaSelectedDesde = _vm.formatDate(_vm.fechaDesde)},"input":function($event){_vm.menuFechaDesde = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0 pl-1 pr-0",attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha hasta","id":"vigencia-hasta-porcentaje-ventas","append-icon":_vm.calendarIcon,"tabindex":"5","hide-details":"auto","autocomplete":"not","clearable":"","hint":"Formato DD/MM/AAAA","rules":_vm.rules.required.concat(
                    _vm.rules.validDate,
                    new Date(_vm.parseDateToIso(_vm.fechaSelectedDesde)) <=
                      new Date(_vm.parseDateToIso(_vm.fechaSelectedHasta)) ||
                      'Rango no válido'
                  ),"outlined":"","dense":""},on:{"blur":function($event){_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaSelectedHasta)}},model:{value:(_vm.fechaSelectedHasta),callback:function ($$v) {_vm.fechaSelectedHasta=$$v},expression:"fechaSelectedHasta"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaHasta),callback:function ($$v) {_vm.menuFechaHasta=$$v},expression:"menuFechaHasta"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaSelectedHasta = _vm.formatDate(_vm.fechaHasta)},"input":function($event){_vm.menuFechaHasta = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1),_c('v-col',{staticClass:"py-0 pl-11 text-right",attrs:{"cols":"2","align":"end"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingAddBtn,"disabled":!_vm.isFormValid,"form":"form","type":"submit","outlined":""}},[_vm._v(" Agregar ")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loadingConfigTable,"headers":_vm.headersConfig,"items":_vm.configAsignAutoMascaras,"dense":"","hide-default-footer":_vm.configAsignAutoMascaras.length < 11},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.editConfig(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar registro")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openDeleteModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar registro")])])]}}],null,true)})],1),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"maxWidth":'35%',"isLoading":_vm.loadingDeleteBtn,"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }