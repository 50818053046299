<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="10" class="py-0">
          <span class="primary--text">{{ modalTitle }}</span>
        </v-col>
        <v-col cols="2" class="py-0 pr-2">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="to-right" icon @click="closeModal">
                <v-icon v-bind="attrs" v-on="on">{{ closeIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form
        v-model="isFormValid"
        ref="form"
        form="form"
        id="form"
        @submit.prevent="saveEdit()"
      >
        <v-row class="pb-0">
          <v-col cols="3" class="py-0 pr-0">
            <v-autocomplete
              v-model="convenioSelected"
              :items="conveniosItems"
              label="Convenio"
              item-text="value"
              item-value="id"
              outlined
              dense
              :rules="rules.required"
              @change="setPlanesByConvenios()"
              return-object
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="3" class="py-0 pl-1 pr-0">
            <v-autocomplete
              outlined
              clearable
              dense
              item-text="value"
              item-value="id"
              :multiple="mascaraCarenciaAutoId == 0"
              return-object
              :loading="loadingPlanesByConvenio"
              v-model="planesSelected"
              label="Planes"
              :items="planesFiltrados"
            >
              <template v-slot:prepend-item v-if="mascaraCarenciaAutoId == 0">
                <v-list-item ripple @click="planesToggle()">
                  <v-list-item-action>
                    <v-icon :color="planesSelected.length > 0 ? 'primary' : ''">
                      {{ multiselectIconPlanes }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Todos </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template
                v-slot:selection="{ item, index }"
                v-if="mascaraCarenciaAutoId == 0"
              >
                <v-chip v-if="index === 0">
                  <span>{{ item.value }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption">
                  (+{{ planesSelected.length - 1 }} otros)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="2" class="py-0 pl-1 pr-0"
            ><v-menu
              v-model="menuFechaDesde"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaSelectedDesde"
                  label="Fecha desde"
                  :append-icon="calendarIcon"
                  v-bind="attrs"
                  autocomplete="not"
                  clearable
                  hint="Formato DD/MM/AAAA"
                  @blur="fechaDesde = parseDateToIso(fechaSelectedDesde)"
                  :rules="
                    rules.required.concat(
                      rules.validDate,
                      fechaSelectedHasta
                        ? [
                            new Date(parseDateToIso(fechaSelectedDesde)) <=
                              new Date(parseDateToIso(fechaSelectedHasta)) ||
                              'Rango no válido'
                          ]
                        : []
                    )
                  "
                  outlined
                  dense
                  v-mask="'##/##/####'"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaDesde"
                no-title
                @change="fechaSelectedDesde = formatDate(fechaDesde)"
                @input="menuFechaDesde = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2" class="py-0 pl-1 pr-0"
            ><v-menu
              v-model="menuFechaHasta"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaSelectedHasta"
                  label="Fecha hasta"
                  id="vigencia-hasta-porcentaje-ventas"
                  :append-icon="calendarIcon"
                  v-bind="attrs"
                  tabindex="5"
                  hide-details="auto"
                  autocomplete="not"
                  clearable
                  hint="Formato DD/MM/AAAA"
                  @blur="fechaHasta = parseDateToIso(fechaSelectedHasta)"
                  :rules="
                    rules.required.concat(
                      rules.validDate,
                      new Date(parseDateToIso(fechaSelectedDesde)) <=
                        new Date(parseDateToIso(fechaSelectedHasta)) ||
                        'Rango no válido'
                    )
                  "
                  outlined
                  dense
                  v-mask="'##/##/####'"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaHasta"
                no-title
                @change="fechaSelectedHasta = formatDate(fechaHasta)"
                @input="menuFechaHasta = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2" align="end" class="py-0 pl-11 text-right">
            <v-btn
              color="primary"
              :loading="loadingAddBtn"
              :disabled="!isFormValid"
              form="form"
              type="submit"
              outlined
            >
              Agregar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        :loading="loadingConfigTable"
        :headers="headersConfig"
        :items="configAsignAutoMascaras"
        class="elevation-1"
        dense
        :hide-default-footer="configAsignAutoMascaras.length < 11"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="editConfig(item)"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar registro</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openDeleteModal(item)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar registro</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>

    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :isLoading="loadingDeleteBtn"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";

export default {
  name: "ConfigAsignacionAutoMascarasCarencias",
  directives: { mask },
  components: { DeleteDialog },
  props: {
    mascaraId: {
      type: Number,
      required: false,
      default: null
    },
    mascaraNom: {
      type: String,
      required: false,
      default: null
    }
  },
  created() {
    this.setConfigAsignAutoMascarasCarencias();
  },
  mounted() {
    this.setSelects();
  },
  data: vm => ({
    modalTitle: `Asignación automática de la máscara: ${vm.mascaraNom}`,
    rules: rules,
    titleDelete: "¿Desea eliminar el registro?",
    showDeleteModal: false,
    configToDelete: null,
    closeIcon: enums.icons.CLOSE,
    calendarIcon: enums.icons.CALENDAR,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    isFormValid: false,
    headersConfig: [
      {
        text: "Convenio",
        align: "start",
        value: "convenio.value",
        sortable: false
      },
      {
        text: "Plan",
        align: "start",
        value: "plan.value",
        sortable: false
      },
      {
        text: "Fecha vigencia desde",
        align: "start",
        value: "fechaVigenciaDesde",
        sortable: false
      },
      {
        text: "Fecha vigencia hasta",
        align: "start",
        value: "fechaVigenciaHasta",
        sortable: false
      },
      {
        text: "Acciones",
        align: "end",
        value: "actions",
        sortable: false
      }
    ],
    configAsignAutoMascaras: [],
    loadingConfigTable: false,
    loadingDeleteBtn: false,
    loadingAddBtn: false,
    conveniosItems: [],
    convenioSelected: null,
    planes: [],
    planesSelected: [],
    loadingPlanesByConvenio: false,
    fechaSelectedDesde: null,
    fechaSelectedHasta: null,
    menuFechaDesde: false,
    menuFechaHasta: false,
    fechaDesde: null,
    fechaHasta: null,
    mascaraCarenciaAutoId: 0
  }),
  computed: {
    multiselectIconPlanes() {
      if (this.selectAllPlanes) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllPlanes() {
      return (
        this.planesSelected &&
        this.planesSelected.length === this.planesFiltrados.length
      );
    },
    planesFiltrados() {
      const idsPlanesAsignados = this.configAsignAutoMascaras.map(
        item => item.plan.id
      );

      if (idsPlanesAsignados.includes(-1)) return [];

      return this.planes.filter(plan => !idsPlanesAsignados.includes(plan.id));
    }
  },
  methods: {
    ...mapActions({
      getConfigAsignAutoMascarasCarencias:
        "afiliaciones/getConfigAsignAutoMascarasCarencias",
      postConfigAsignAutoMascarasCarencias:
        "afiliaciones/postConfigAsignAutoMascarasCarencias",
      deleteConfigAsignAutoMascarasCarencias:
        "afiliaciones/deleteConfigAsignAutoMascarasCarencias",
      getConvenios: "aportes/fetchConvenios",
      getPlanesByConvenio: "configAfiliaciones/getPlanesByConvenio",
      setAlert: "user/setAlert"
    }),
    async setConfigAsignAutoMascarasCarencias() {
      this.loadingConfigTable = true;
      try {
        const response = await this.getConfigAsignAutoMascarasCarencias(
          this.mascaraId
        );
        this.configAsignAutoMascaras = response;
      } catch (error) {
        console.error(error);
      }
      this.loadingConfigTable = false;
    },
    async setSelects() {
      const convenios = await this.getConvenios();
      this.conveniosItems = convenios;
    },
    async setPlanesByConvenios() {
      this.planes = this.planesSelected = [];
      if (this.convenioSelected != null) {
        this.loadingPlanesByConvenio = true;
        const planes = await this.getPlanesByConvenio([
          this.convenioSelected.id
        ]);

        this.planes = planes;

        this.loadingPlanesByConvenio = false;
      }
    },
    async saveEdit() {
      this.loadingAddBtn = true;
      try {
        const data = {
          mascarasCarenciasAutoId: this.mascaraCarenciaAutoId,
          mascaraCarenciasId: this.mascaraId,
          osId: this.convenioSelected?.id,
          planesId:
            this.mascaraCarenciaAutoId > 0
              ? [this.planesSelected?.id]
              : this.planesSelected.length == 0
              ? null
              : this.planesSelected.length == this.planes.length
              ? null
              : this.planesSelected.map(x => x.id),
          fechaVigenciaDesde: this.fechaDesde,
          fechaVigenciaHasta: this.fechaHasta
        };
        const response = await this.postConfigAsignAutoMascarasCarencias(data);
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Agregado con éxito." });
          this.setConfigAsignAutoMascarasCarencias();
          this.$refs.form.reset();
          this.planes = this.planesSelected = [];
          this.mascaraCarenciaAutoId = 0;
        }
      } catch (error) {
        console.error(error);
      }
      this.loadingAddBtn = false;
    },
    editConfig(item) {
      this.configAsignAutoMascaras = this.configAsignAutoMascaras.filter(
        x => x != item
      );
      this.mascaraCarenciaAutoId = item.mascarasCarenciasAutoId;
      this.convenioSelected = item.convenio;
      this.setPlanesByConvenios();
      this.planesSelected = item.plan.id == -1 ? [] : item.plan;
      this.fechaSelectedDesde = item.fechaVigenciaDesde;
      this.fechaSelectedHasta = item.fechaVigenciaHasta;
      this.fechaDesde = this.parseDateToIso(item.fechaVigenciaDesde);
      this.fechaHasta = this.parseDateToIso(item.fechaVigenciaHasta);
    },
    openDeleteModal(item) {
      this.showDeleteModal = true;
      this.configToDelete = item.mascarasCarenciasAutoId;
    },
    async confirmDelete() {
      this.loadingDeleteBtn = true;
      try {
        const response = await this.deleteConfigAsignAutoMascarasCarencias(
          this.configToDelete
        );
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.setConfigAsignAutoMascarasCarencias();
        }
      } catch (error) {
        console.error(error);
      }
      this.loadingDeleteBtn = this.showDeleteModal = false;
    },
    planesToggle() {
      this.$nextTick(() => {
        if (this.selectAllPlanes) {
          this.planesSelected = [];
        } else {
          this.planesSelected = this.planesFiltrados;
        }
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
