<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <v-row>
          <v-col cols="1" class="pb-2">
            <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
          </v-col>
          <v-col cols="11" class="py-0 pl-0">
            <PageHeader :title="title" />
          </v-col>
        </v-row>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="mascaras"
            class="elevation-1"
            item-key="mascarasCarenciasId"
            :search="search"
            :loading="isLoading"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      @click="openModal(null, 'new')"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.hasConfigAsignAuto`]="{ item }">
              <v-icon v-if="item.hasConfigAsignAuto" small color="primary">
                {{ checkIcon }}
              </v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canConfigAsignacionAutomatica">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModalConfigAsignAuto(item)"
                  >
                    {{ configIcon }}
                  </v-icon>
                </template>
                <span>Configurar asignación automática</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModal(item, 'see')"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver máscara</span>
              </v-tooltip>
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModal(item, 'edit')"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar máscara</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteMasc(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar máscara</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-0 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="75%"
      @keydown.esc="closeAndReload()"
      persistent
    >
      <EditConfiguracionMascarasCarencias
        :mascaraId="mascaraId"
        :mascaraNom="mascaraNom"
        :action="openModalAction"
        @closeAndReload="closeAndReload()"
      ></EditConfiguracionMascarasCarencias>
    </v-dialog>
    <v-dialog
      v-if="modalConfigAsignAuto"
      v-model="modalConfigAsignAuto"
      max-width="85%"
      @keydown.esc="closeAndReload()"
      persistent
    >
      <ConfigAsignacionAutoMascarasCarencias
        :mascaraId="mascaraId"
        :mascaraNom="mascaraNom"
        @closeAndReload="closeAndReload()"
      ></ConfigAsignacionAutoMascarasCarencias>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'45%'"
      :isLoading="loadingEliminar"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditConfiguracionMascarasCarencias from "@/components/modules/afiliaciones/afiliados/configuracion/EditConfiguracionMascarasCarencias.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";
import ConfigAsignacionAutoMascarasCarencias from "@/components/modules/afiliaciones/afiliados/configuracion/ConfigAsignacionAutoMascarasCarencias.vue";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    EditConfiguracionMascarasCarencias,
    ConfigAsignacionAutoMascarasCarencias,
    Ayuda
  },
  name: "ConfiguracionMascarasCarencias",
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    configIcon: enums.icons.SETTINGS,
    checkIcon: enums.icons.CHECK_OUTLINE,
    seeIcon: enums.icons.SEE,
    search: "",
    isLoading: false,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    loadingEliminar: false,
    optionCode: enums.webSiteOptions.CONFIG_MASCARAS_CARENCIAS,
    showDeleteModal: false,
    idToDelete: null,
    routeToGo: "AfiliacionesConfiguracion",
    title: enums.titles.CONFIG_MASCARAS_CARENCIAS,
    titleDelete: enums.titles.DELETE_CONFIG_MASCARA,
    rules: rules,
    mascaras: [],
    mascaraId: null,
    mascaraNom: "",
    headers: [
      {
        text: "Máscara",
        align: "start",
        value: "mascaraNom",
        sortable: false
      },
      {
        text: "Asignación automática",
        align: "center",
        value: "hasConfigAsignAuto",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    canConfigAsignacionAutomatica: false,
    openModalEdit: false,
    modalConfigAsignAuto: false,
    openModalAction: null
  }),
  async created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    await this.loadMascara();
  },
  mounted() {
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getMascarasCarencias: "afiliaciones/getMascarasCarencias",
      deleteMascaraCarencia: "afiliaciones/deleteMascaraCarencia",
      setAlert: "user/setAlert"
    }),
    async loadMascara() {
      const data = await this.getMascarasCarencias(-1);
      this.mascaras = data;
    },
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVA_MASCARA_CARENCIAS:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_MASCARA_CARENCIAS:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .CONFIG_ASIGNACION_AUTOMATICA_CARENCIAS:
            this.canConfigAsignacionAutomatica = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_MASCARA_CARENCIAS:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    deleteMasc(item) {
      this.showDeleteModal = true;
      this.itemToDelete = item.id;
    },
    async confirmDelete() {
      this.loadingEliminar = true;
      const response = await this.deleteMascaraCarencia(this.itemToDelete);
      if (response.status == 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadingEliminar = false;
        this.showDeleteModal = false;
        this.loadMascara();
      }
    },
    openModal(item, action) {
      this.openModalEdit = true;
      this.mascaraId = item?.mascarasCarenciasId ?? null;
      this.mascaraNom = item?.mascaraNom ?? "";
      this.openModalAction = action;
    },
    openModalConfigAsignAuto(item) {
      this.modalConfigAsignAuto = true;
      this.mascaraId = item?.mascarasCarenciasId ?? null;
      this.mascaraNom = item?.mascaraNom ?? "";
    },
    closeAndReload() {
      this.openModalEdit = this.modalConfigAsignAuto = false;
      this.loadMascara();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
