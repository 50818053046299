<template>
  <v-card>
    <v-card-title class="py-0 pr-4">
      <v-col cols="12" align="end" class="text-right pb-0 pt-1 pr-0">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="to-right text-right" icon @click="closeModal()">
              <v-icon v-bind="attrs" v-on="on">{{ closeIcon }}</v-icon>
            </v-btn>
          </template>
          <span>Cerrar</span>
        </v-tooltip>
      </v-col>
      <v-row>
        <v-col cols="12" md="7" lg="7" class="pt-0 pr-0 d-flex justify-start">
          <span class="pl-0 pb-1 pr-4 primary--text">{{ formEditTitle }}</span>
          <v-text-field
            v-model="mascaraNomEdit"
            :label="mascaraId ? '' : 'Nombre máscara'"
            dense
            :disabled="mascaraId !== null"
            autocomplete="off"
            :rules="
              rules.required.concat([
                rules.requiredTrim(mascaraNomEdit),
                rules.maxLength(mascaraNomEdit, 50)
              ])
            "
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="2"
          lg="2"
          class="pt-0 pb-5 d-flex justify-start"
          v-if="action != 'see'"
        >
          <v-form
            v-model="isFormValidMascaraNom"
            ref="mascaraNom-form"
            id="mascaraNom-form"
          >
            <v-menu
              v-if="mascaraId"
              v-model="menuEditarNombre"
              :close-on-content-click="false"
              :close-on-click="!isFormValidMascaraNom ? false : true"
              offset-x
              left
              transition="scale-transition"
              style="position: absolute; z-index: 9999"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  style="cursor: pointer"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <v-card class="pa-2" min-width="380px" elevation="5">
                <v-text-field
                  v-model="mascaraNomEdit"
                  label="Nuevo nombre"
                  dense
                  outlined
                  :rules="
                    rules.required.concat([
                      rules.requiredTrim(mascaraNomEdit),
                      rules.maxLength(mascaraNomEdit, 50)
                    ])
                  "
                ></v-text-field>
                <v-card-actions class="justify-end pr-0">
                  <v-btn
                    :disabled="
                      mascaraNomEdit.trim() == '' || !isFormValidMascaraNom
                    "
                    color="primary"
                    :loading="loadingBtnNombre"
                    @click="saveNomMascara"
                    >GUARDAR</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-form>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form v-model="isFormValid" ref="form" form="form" id="form">
        <v-row class="pb-0" v-if="action != 'see'">
          <v-col cols="4" class="py-0 pr-0">
            <v-autocomplete
              :items="agrupaciones"
              v-model="agrupacionSelected"
              label="Agrupación"
              item-text="value"
              item-value="id"
              return-object
              hide-details="auto"
              :rules="rules.required"
              outlined
              clearable
              @change="getGruposByAgrupacionId()"
              dense
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="4" class="py-0 pl-2 pr-0">
            <v-autocomplete
              :items="grupos"
              v-model="grupoSelected"
              label="Grupo de prácticas"
              item-text="value"
              item-value="id"
              return-object
              hide-details="auto"
              :rules="rules.required"
              outlined
              clearable
              :append-icon="agrupacionSelected != null && grupoSelected != null ? seeIcon : null"
              @click:append="goToVerDetalleGrupo(grupoSelected.id)"
              dense
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="2" class="py-0 pl-2">
            <v-text-field
              v-model="dias"
              label="Días"
              type="number"
              :rules="
                rules.required.concat([rules.minMaxNumber(dias, 1, 9999)])
              "
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="2" align="end" class="py-0 pl-11 text-right">
            <v-btn
              color="primary"
              :disabled="
                !isFormValid ||
                  !grupoSelected ||
                  !agrupacionSelected ||
                  !dias ||
                  !mascaraNomEdit
              "
              :loading="loadingAdd"
              outlined
              @click="addConfigMascara"
            >
              Agregar
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table
          :loading="loadingTable"
          :headers="headers"
          :items="configMascaras"
          class="elevation-1"
          dense
          :hide-default-footer="configMascaras.length < 11"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="goToVerDetalleGrupo(item.gruId)"
                >
                  {{ seeIcon }}
                </v-icon>
              </template>
              <span>Ver detalle del grupo</span>
            </v-tooltip>
            <v-tooltip left v-if="action != 'see'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openDeleteModal(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar máscara</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-form>
    </v-card-text>
    <v-dialog
      v-if="openDetalleGrupoModal"
      v-model="openDetalleGrupoModal"
      max-width="60%"
      @keydown.esc="closeAndReloadDetalle"
      persistent
    >
      <DetalleGrupoMascara
        @closeAndReloadDetalle="closeAndReloadDetalle"
        :gruId="this.gruIdToDetalle"
      ></DetalleGrupoMascara>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'45%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import DetalleGrupoMascara from "@/components/modules/afiliaciones/afiliados/DetalleGrupoMascara.vue";

export default {
  name: "EditConfiguracionMascarasCarencias",
  directives: { mask },
  components: { DetalleGrupoMascara, DeleteDialog },
  props: {
    mascaraId: {
      type: Number,
      required: false,
      default: null
    },
    mascaraNom: {
      type: String,
      required: false,
      default: null
    },
    action: {
      type: String,
      required: true,
      default: "see"
    }
  },
  async created() {
    if (this.action === "see")
      this.formEditTitle = "Ver configuración de la máscara: ";
    else if (this.action === "edit")
      this.formEditTitle = "Editar configuración de máscara: ";
    else this.formEditTitle = "Nueva configuración de la máscara: ";

    await this.setAgrupaciones();
    if (this.mascaraId) {
      this.setConfigMascara(this.mascaraId);
      this.isEdit = true;
    }
    this.mascaraNomEdit = this.mascaraNom;
  },
  data: () => ({
    formEditTitle: null,
    rules: rules,
    titleDelete: "¿Desea eliminar configuración de máscara?",
    showDeleteModal: false,
    isFormValidMascaraNom: false,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    closeIcon: enums.icons.CLOSE,
    seeIcon: enums.icons.SEE,
    isFormValid: false,
    isEdit: false,
    menuEditarNombre: false,
    agrupacionSelected: null,
    loadingAdd: false,
    loadingBtnNombre: false,
    agrupaciones: [],
    headers: [
      {
        text: "Agrupación",
        align: "left",
        value: "agruNom",
        sortable: false
      },
      {
        text: "Grupo",
        align: "left",
        value: "gruNom",
        sortable: false
      },
      {
        text: "Días",
        align: "end",
        value: "dias",
        sortable: false
      },
      {
        text: "Acciones",
        align: "end",
        value: "actions",
        sortable: false
      }
    ],
    grupos: [],
    dias: null,
    mascaraNomEdit: "",
    grupoSelected: null,
    configMascaras: [],
    gruIdToDelete: null,
    mascarasCarenciasIdToDelete: null,
    loadingTable: false,
    newMascaraId: 0,
    gruIdToDetalle: null,
    openDetalleGrupoModal: false
  }),
  methods: {
    ...mapActions({
      getConfigMascarasCarenciasById:
        "afiliaciones/getConfigMascarasCarenciasById",
      getGrupos: "afiliaciones/getGrupos",
      getAgrupacionesPracticas: "analytics/getAgrupacionesPracticas",
      setAlert: "user/setAlert",
      deleteConfigMascaraCarencia: "afiliaciones/deleteConfigMascaraCarencia",
      addConfiguracionMascara: "afiliaciones/addConfiguracionMascara"
    }),
    async setConfigMascara(mascaraId) {
      const data = await this.getConfigMascarasCarenciasById(mascaraId);
      this.configMascaras = data;
      this.loadingTable = false;
    },
    async getGruposByAgrupacionId() {
      this.grupoSelected = null;
      if (!this.agrupacionSelected) return;

      const gruposPractica = await this.getGrupos(this.agrupacionSelected.id);
      this.grupos = gruposPractica;
    },
    async setAgrupaciones() {
      const agrup = await this.getAgrupacionesPracticas();
      this.agrupaciones = agrup;
    },
    openDeleteModal(item) {
      this.showDeleteModal = true;
      this.gruIdToDelete = item.gruId;
      this.mascarasCarenciasIdToDelete = item.mascarasCarenciasId;
    },
    async confirmDelete() {
      this.loadingTable = true;
      const payload = {
        mascarasCarenciasId: this.mascarasCarenciasIdToDelete,
        gruId: this.gruIdToDelete
      };
      const data = await this.deleteConfigMascaraCarencia(payload);
      if (data) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        await this.setConfigMascara(
          this.isEdit ? this.mascaraId : this.newMascaraId
        );
      }
    },
    async addConfigMascara() {
      if (this.configMascaras.some(x => x.gruId == this.grupoSelected.id)) {
        this.setAlert({
          type: "warning",
          message: "Ya existe una configuración para este grupo."
        });
        return;
      }
      this.loadingAdd = true;
      const mascaraConfig = {
        mascarasCarenciasId: this.isEdit ? this.mascaraId : this.newMascaraId,
        mascaraNom:
          this.mascaraNomEdit == this.mascaraNom ? "" : this.mascaraNomEdit,
        gruNom: this.grupoSelected.value,
        gruId: this.grupoSelected.id,
        dias: this.dias
      };
      try {
        const response = await this.addConfiguracionMascara(mascaraConfig);

        if (response > 0)
          this.setAlert({ type: "success", message: "Agregado con éxito." });
        this.$refs.form.reset();
        this.newMascaraId = response;
        await this.setConfigMascara(response);
      } catch (error) {
        console.error(error);
      }
      this.loadingAdd = false;
    },
    async saveNomMascara() {
      this.loadingBtnNombre = true;
      const mascaraConfig = {
        mascarasCarenciasId: this.mascaraId,
        soloEditNombre: true,
        mascaraNom: this.mascaraNomEdit
      };
      const response = await this.addConfiguracionMascara(mascaraConfig);
      if (response)
        this.setAlert({ type: "success", message: "Guardado con éxito." });
      this.loadingBtnNombre = false;
      this.menuEditarNombre = false;
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    goToVerDetalleGrupo(id) {
      this.openDetalleGrupoModal = true;
      this.gruIdToDetalle = id;
    },
    closeAndReloadDetalle() {
      this.openDetalleGrupoModal = false;
    }
  }
};
</script>
